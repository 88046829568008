import React from 'react';

const CONTEXT_KEY = Symbol.for('@thd-olt-component-react/layout-manager/Context');
const globalSymbols = Object.getOwnPropertySymbols(global);
const hasContext = (globalSymbols.indexOf(CONTEXT_KEY) > -1);

if (!hasContext) {
  global[CONTEXT_KEY] = React.createContext({});
}

const LayoutManagerContext = global[CONTEXT_KEY];

export { LayoutManagerContext };
