/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { RatingsAndReviewsMobile } from './RatingsAndReviewsMobile';
import { RatingsAndReviewsDesktop } from './RatingsAndReviewsDesktop';
import { dataModel } from './ReviewDataModel';
import { RatingsAndReviewsDefaultProps, RatingsAndReviewsPropTypes } from './RatingsAndReviewsPropTypes';
import { RatingsAndReviewsCondensed } from './RatingsAndReviewsCondensed';
import { StoreRatingsAndReviewsDesktop } from './StoreRatingsAndReviewsDesktop';
import { StoreRatingsAndReviewsMobile } from './StoreRatingsAndReviewsMobile';

export const RatingsAndReviews = (props) => {
  const { channel } = useContext(ExperienceContext);
  const { forceCondensedLayout, category = 'product' } = props;

  if (forceCondensedLayout) {
    return (
      <RatingsAndReviewsCondensed {...props} />
    );
  }

  if (channel === 'mobile' && category === 'product') {
    return (
      <RatingsAndReviewsMobile {...props} />
    );
  }

  if (channel === 'desktop' && category === 'store') {
    return (
      <StoreRatingsAndReviewsDesktop {...props} />
    );
  }

  if (channel === 'mobile' && category === 'store') {
    return (
      <StoreRatingsAndReviewsMobile {...props} />
    );
  }

  return (
    <RatingsAndReviewsDesktop {...props} />
  );
};

RatingsAndReviews.displayName = 'RatingsAndReviews';

RatingsAndReviews.propTypes = RatingsAndReviewsPropTypes;

RatingsAndReviews.defaultProps = RatingsAndReviewsDefaultProps;

RatingsAndReviews.dataModel = dataModel;
