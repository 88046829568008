/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { usePresentation } from '../../context/PresentationProvider';
import { dataModel } from '../ReviewDataModel';
import { OverviewMobile } from './OverviewMobile';
import { OverviewDesktop } from './OverviewDesktop';
import { OverviewDefaultProps, OverviewPropTypes } from './OverviewPropTypes';

export const Overview = (props) => {
  const { useCondensedLayout } = usePresentation();
  if (useCondensedLayout) {
    return <OverviewMobile {...props} />;
  }

  return <OverviewDesktop {...props} />;
};

Overview.displayName = 'RatingsAndReviewsOverview';

Overview.propTypes = OverviewPropTypes;

Overview.defaultProps = OverviewDefaultProps;

Overview.dataModel = dataModel;
