import React from 'react';
import ReactDOM from 'react-dom';
import {
  shape, node, arrayOf, oneOfType, bool
} from 'prop-types';
import { LayoutManagerContext } from '../LayoutManagerContext';
import { LayoutGroupContext } from '../component/LayoutGroupContext';

export const withLayoutGroup = (opts, WrappedComponent) => {
  class LayoutGroup extends React.Component {

    // eslint-disable-next-line react/static-property-placement
    static contextType = LayoutManagerContext;

    // eslint-disable-next-line react/static-property-placement
    static displayName = 'LayoutGroup';

    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    boundClickListener = false;

    state = {};

    componentDidMount() {
      const { layoutGroup } = this.props;
      if (!layoutGroup) return;
      const name = layoutGroup.name || opts.name || 'UndefinedLayoutGroup';

      if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
        const { rules } = this.context;
        window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('LAYOUT_GROUP.ready', {
          ...this.props,
          ...rules
        });
      }
      try {
        const el = ReactDOM.findDOMNode(this);// eslint-disable-line
        if (el && el.setAttribute) {
          el.setAttribute('data-lg-name', name);
        }
      } catch (err) {
        // silence
      }
    }

    componentDidUpdate() {
      const { layoutGroup } = this.props;
      if (!layoutGroup) return;
      const name = layoutGroup.name || opts.name || 'UndefinedLayoutGroup';

      if (!this.boundClickListener) {
        if (this.to) {
          clearTimeout(this.to);
        }
        this.to = setTimeout(() => {
          try {
            const el = ReactDOM.findDOMNode(this);// eslint-disable-line
            if (el && el.setAttribute) {
              el.setAttribute('data-lg-name', name);
            }
          } catch (err) {
            // silence
          }

        }, 1000);
      }

    }

    componentDidCatch(error, info) {
      console.log(error);
      const { layoutGroup } = this.props;
      if (!layoutGroup) {
        throw error;
      }
      const { name = 'UndefinedLayoutGroup' } = opts;
      const { componentStack } = info || {};
      const { onError } = this.context;
      if (onError) {
        onError({ error, info, name });
      }
      if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {

        window.LIFE_CYCLE_EVENT_BUS.trigger('layout-manager.error', {
          error,
          name,
          componentStack
        });
      }
      return null;
    }

    render() {

      const { children, layoutGroup } = this.props;

      if (!layoutGroup) {
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <WrappedComponent {...this.props}>{children}</WrappedComponent>
        );
      }
      const lgName = layoutGroup?.name || opts.name;
      const { rules, manager, useContextForProps } = this.context;
      const managers = rules?.managers || [];
      const group = (manager?.groups || []).find((mgr) => mgr.name === lgName);
      const layoutGroupContext = {
        managerName: manager?.name,
        groupName: lgName,
        props: group?.props,
        useContextForProps
      };

      const { hasError } = this.state;
      if (hasError) {
        return null;
      }
      const Tag = this.props?.layoutGroup?.tag || opts?.tag || React.Fragment;
      return (
        <LayoutGroupContext.Provider value={layoutGroupContext}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Tag {...(this.props?.layoutGroup?.tagProps || opts.tagProps || {})}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <WrappedComponent {...this.props} layoutGroup={opts} />
          </Tag>
        </LayoutGroupContext.Provider>
      );
    }
  }

  LayoutGroup.propTypes = {
    layoutGroup: oneOfType([bool, shape({
      tag: node,
      tagProps: shape({}),
    })]),
    children: oneOfType([
      arrayOf(node),
      node
    ])
  };

  LayoutGroup.defaultProps = {
    layoutGroup: false,
    children: null,
  };
  LayoutGroup.layoutGroupName = opts.name;
  LayoutGroup.dataModel = WrappedComponent?.dataModel || {};
  LayoutGroup.displayName = WrappedComponent.displayName || 'LayoutGroup';
  LayoutGroup.wraps = {
    ...(WrappedComponent.wraps || {}),
    layoutGroup: true
  };

  return LayoutGroup;

};
