import React, { useState, useContext, useEffect } from 'react';
import { useParams } from '@thd-olt-component-react/router';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import { withDynamicComponent, AppContext } from '@thd-nucleus/app-render';
import { bool, string } from 'prop-types';
import { Accordion, AccordionBody, AccordionHeader } from '@one-thd/sui-atomic-components';
import { dataModel } from '../ReviewDataModel';
import { RatingsAndReviewsDefaultProps, RatingsAndReviewsPropTypes } from '../RatingsAndReviewsPropTypes';
import { RatingsAndReviewsAccordionBody } from './RatingsAndReviewsAccordionBody';
import { RatingsAndReviewsAccordionTitle } from './RatingsAndReviewsAccordionTitle';
import '../ratings-and-reviews.style.scss';

const RatingsAndReviewsAccordion = ({
  category,
  disableOverlayOnClick,
  itemId,
  key,
  noSpacing,
  prosAndCons,
  seoPageNumber
}) => {
  const itemIdParam = useParams();
  const itemIdFinal = itemId || itemIdParam?.itemId;
  const { instance } = useContext(AppContext);
  const thdSeoBotDetected = instance?.thdSEOBotDetection;

  const [expanded, setExpanded] = useState(thdSeoBotDetected || false);

  const handleAccordionChange = (canCloseAccordion = true) => {
    if (canCloseAccordion) {
      setExpanded(!expanded);
    } else if (!canCloseAccordion && !expanded) {
      setExpanded(true);
    }
  };

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('product-section-rr.click', ({ output = true }) => {
      handleAccordionChange(output);
    });
  }, []);

  return (
    <Accordion
      key={key || 'ratings-and-reviews-accordion'}
      expanded={expanded}
      onChange={() => handleAccordionChange()}
    >
      <AccordionHeader>
        <RatingsAndReviewsAccordionTitle
          noSpacing={noSpacing}
          itemId={itemIdFinal}
        />
      </AccordionHeader>
      <AccordionBody>
        <RatingsAndReviewsAccordionBody
          itemId={itemIdFinal}
          prosAndCons={prosAndCons}
          seoPageNumber={seoPageNumber}
          disableOverlayOnClick={disableOverlayOnClick}
          category={category}
        />
      </AccordionBody>
    </Accordion>
  );
};

RatingsAndReviewsAccordion.displayName = 'RatingsAndReviewsAccordion';

RatingsAndReviewsAccordion.propTypes = {
  ...RatingsAndReviewsPropTypes,
  key: string,
  noSpacing: bool,
};

RatingsAndReviewsAccordion.defaultProps = {
  ...RatingsAndReviewsDefaultProps,
  key: null,
  noSpacing: false,
};

RatingsAndReviewsAccordion.dataModel = dataModel;

const HydratedComponent = withHydrator({
  id: 'product-section-ratings-reviews',
  scrollBuffer: 900,
  delay: 200,
  placeholder: (
    <div data-component="RatingsAndReviewsAccordionPlaceholder">
      <Placeholder
        width="100%"
        height="1500px"
        color="#F5F5F5"
      />
    </div>
  ),
}, RatingsAndReviewsAccordion);
const DynamicComponent = withDynamicComponent(HydratedComponent);
const ErrorComponent = withErrorBoundary(DynamicComponent);

export { ErrorComponent as RatingsAndReviewsAccordion };