import React, { useEffect, useRef, useState } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { ReviewModel } from './models/ReviewModel';
import { ReviewStatsModel } from './models/ReviewStatsModel';
import { pipReviewsPerPage, seoReviewsPerPage } from '../constants';

// TODO: UPDATE DEPRECATED HOOKS
/**
 * This hook calls the review services (BV) and falls back to review data from product model if it fails.
 * @param itemId
 * @param options - RR state which contains filtering options for the BV call
 * @returns { averageRating, loading, FilterSelected, reviewModels, statistics, SortBy, totalReviews } -
 *   based on available data source
 */
export const useStoreReviews = ({ storeId, seoPageNumber, ...options }) => {
  const {
    filters, pageContext, searchText, selectedSentiment, sortBy
  } = options;
  const initialOptions = useRef({});

  let seoStartIndex = Math.max(((seoPageNumber || 1) - 1) * seoReviewsPerPage, 1);
  seoStartIndex = seoPageNumber > 1 ? seoStartIndex + 1 : seoStartIndex;

  const pageSize = seoPageNumber ? seoReviewsPerPage : pipReviewsPerPage;

  const [queryVariables, setQueryVariables] = useState({
    storeId,
    filters: {
      isVerifiedPurchase: false,
      prosCons: null,
      starRatings: null,
      profile: null,
      gender: null,
      ageRange: null
    },
    searchTerm: null,
    sortBy: 'photoreview',
    startIndex: seoStartIndex,
    recfirstpage: pageSize.toString(),
    pagesize: pageSize.toString()
  });

  const filterValue = (checkedFilters, comparableValue) => {
    return checkedFilters.filter((item) => {
      let check = item.id.toString().includes(comparableValue) ? true : null;
      return check;
    })
      .map((item) => {
        return item.value;
      });
  };

  useEffect(() => {
    if (!options) return;
    if (!initialOptions?.current?.pageContext) {
      initialOptions.current = options;
      return;
    }
    if (JSON.stringify(options) === JSON.stringify(initialOptions.current)) return;
    initialOptions.current = options;
    const { currentPage } = pageContext;
    const verifiedFilter = filters.find((eachFilter) => eachFilter.id === 'verified');
    const checkedFilters = filters.filter((eachFilter) => eachFilter.id !== 'verified' && eachFilter.checked);
    const isVerifiedPurchase = verifiedFilter && verifiedFilter.checked;

    const starFilter = checkedFilters.find((eachFilter) => eachFilter.id.toString().includes('rating'));
    const starRatings = starFilter ? filterValue(checkedFilters, 'rating') : null;

    const ageFilter = checkedFilters.find((eachFilter) => eachFilter.id.toString().includes('age'));
    const ageRange = ageFilter ? filterValue(checkedFilters, 'age') : null;

    const profileFilter = checkedFilters.find((eachFilter) => eachFilter.id.toString().includes('profile'));
    const profile = profileFilter ? filterValue(checkedFilters, 'profile') : null;

    const genderFilter = checkedFilters.find((eachFilter) => eachFilter.id.toString().includes('gender'));
    const gender = genderFilter ? filterValue(checkedFilters, 'gender') : null;

    const reviewsPerPage = seoPageNumber ? seoReviewsPerPage : pipReviewsPerPage;

    const reviewQueryFilters = {
      isVerifiedPurchase,
      prosCons: null,
      starRatings,
      ageRange,
      profile,
      gender
    };

    let startIndex = Math.max((currentPage - 1) * reviewsPerPage, 1);
    startIndex = currentPage > 1 ? startIndex + 1 : startIndex;
    const variables = {
      storeId,
      filters: reviewQueryFilters,
      searchTerm: searchText,
      sortBy,
      startIndex,
      recfirstpage: pageSize.toString(),
      pagesize: pageSize.toString()
    };
    setQueryVariables(variables);
  }, [options]);

  const { data, loading, error } = useDataModel('storeReviews', {
    variables: queryVariables
  });

  const storeReviewData = data?.storeReviews;

  if (loading) {
    return { loading: true };
  }

  if (storeReviewData) {
    const { FilterSelected, Results, SortBy } = storeReviewData;
    const results = Results || [];
    const reviewModels = results.map((result) => new ReviewModel(result));
    const statistics = new ReviewStatsModel(storeReviewData);
    return { FilterSelected, reviewModels, statistics, SortBy };
  }

  return {};

};