import React, { useEffect, useReducer, useRef } from 'react';
import { RatingsAndReviewsMobileContent } from './Mobile/RatingsAndReviewsMobileContent';
import { useStoreReviews } from './useStoreReviews';
import { PresentationProvider } from '../context/PresentationProvider';
import { dataModel } from './ReviewDataModel';
import { storeInitialState } from '../constants';
import { stateReducer } from '../reducer';
import { RatingsAndReviewsDefaultProps, StoreRatingsAndReviewsPropTypes } from './RatingsAndReviewsPropTypes';
import { RatingsAndReviewsPlaceholder } from './RatingsAndReviewsPlaceholder';
import { onFilterChange } from '../helpers';
import './ratings-and-reviews.style.scss';

export const StoreRatingsAndReviewsMobile = ({
  apiKey, children, storeId, prosAndCons, target, seoPageNumber, category, seoStoreLink
}) => {
  const [state, dispatch] = useReducer(stateReducer, storeInitialState);
  const persistedStatistics = useRef({});
  const initialReviews = useRef({});
  const persistedPageContext = useRef();

  useEffect(() => {
    if (seoPageNumber) {
      dispatch({ type: 'sort', value: 'photoreview' });
      dispatch({ type: 'pageLoad', value: seoPageNumber });
    }
  }, [seoPageNumber]);

  const {
    averageRating, FilterSelected, loading, reviewModels = [], statistics, totalReviews
  } = useStoreReviews({ storeId, seoPageNumber, ...state });

  useEffect(() => {
    if (!persistedPageContext.current) {
      persistedPageContext.current = state.pageContext;
      return;
    }
    if (persistedPageContext.current.currentPage !== state.pageContext.currentPage) {
      persistedPageContext.current = state.pageContext;
    }
  }, [state.pageContext]);

  const {
    filters, pageContext, searchText, selectedSentiment, sortBy
  } = state;
  const sentimentChange = (sentiment) => dispatch({ type: 'sentiment', value: sentiment });
  if (loading && !persistedStatistics.current[storeId]) {
    return (
      <RatingsAndReviewsPlaceholder
        color="#E0E0E0"
        showLoadingAnimation
      />
    );
  }
  const selected = selectedSentiment ? [selectedSentiment] : filters.filter((rf) => rf.checked);
  const updatedTotalpage = selectedSentiment
    ? Math.ceil(selectedSentiment?.TotalResults / 10) || 1
    : statistics?.totalPages || 1;
  if (state.pageContext.totalPages !== updatedTotalpage) {
    dispatch({ type: 'totalPages', value: updatedTotalpage });
  }
  const { SearchText } = FilterSelected || {};
  if (persistedStatistics.current && !persistedStatistics.current[storeId]) {
    persistedStatistics.current[storeId] = statistics;
    initialReviews.current[storeId] = reviewModels;
  }
  const reviewStatistics = persistedStatistics.current && persistedStatistics.current[storeId]
    ? persistedStatistics.current[storeId]
    : statistics;
  const noResults = state.searchText && reviewModels.length === 0 && !loading;

  const firstThreeReviews = reviewModels.slice(0, 3).filter((review) => !!review);
  return (
    <PresentationProvider useCondensedLayout>
      <div className="ratings-reviews" id="ratings-and-reviews" data-component="StoreRatingsAndReviewsMobile">
        <RatingsAndReviewsMobileContent
          apiKey={apiKey}
          filterCheckboxes={filters}
          storeId={storeId}
          category={category}
          onDeselectFilter={(checkbox) => dispatch({ type: 'removeFilter', value: checkbox })}
          onFilterChange={
            (updatedFilters) => onFilterChange(updatedFilters, { dispatch, useCondensedLayout: true, category })
          }
          onAdditionalFilterChange={(updatedFilters) => onFilterChange(updatedFilters, { dispatch, category })}
          mobileReviewsOnFirstPage={firstThreeReviews}
          noResults={noResults}
          onSearch={(searchTerm) => {
            if (searchTerm) { dispatch({ type: 'search', value: searchTerm }); }
          }}
          onPageChange={(updatedPageContext) => dispatch({
            type: 'pageChange',
            value: updatedPageContext
          })}
          onSentimentChange={sentimentChange}
          onSearchClear={() => dispatch({ type: 'search', value: null })}
          onSortChange={(updatedSortValue) => dispatch({ type: 'sort', value: updatedSortValue })}
          searchTerm={SearchText}
          selected={selected}
          sortBy={sortBy}
          reviewModels={reviewModels}
          reviewStatistics={reviewStatistics}
          selectedSentiment={selectedSentiment}
          target={target}
          statistics={statistics}
          pageContext={pageContext}
          loading={loading}
          prosAndCons={prosAndCons}
          seoStoreLink={seoStoreLink}
          seoPageNumber={seoPageNumber}
        >
          {children}
        </RatingsAndReviewsMobileContent>
      </div>
    </PresentationProvider>
  );
};

StoreRatingsAndReviewsMobile.displayName = 'StoreRatingsAndReviewsMobile';

StoreRatingsAndReviewsMobile.propTypes = StoreRatingsAndReviewsPropTypes;

StoreRatingsAndReviewsMobile.defaultProps = RatingsAndReviewsDefaultProps;

StoreRatingsAndReviewsMobile.dataModel = dataModel;
