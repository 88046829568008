import { RatingsAndReviews } from './component/RatingsAndReviews.component';
import { Model } from './component/models/Model';
import { ReviewModel } from './component/models/ReviewModel';
import { ReviewStatsModel } from './component/models/ReviewStatsModel';
import { ReviewSentimentModel } from './component/models/ReviewSentimentModel';
import { WriteAReviewWithPresentation } from './component/WriteAReview/WriteAReviewWithPresentation.component';
import { RatingsAndReviewsDesktop } from './component/RatingsAndReviewsDesktop';
import { RatingsAndReviewsDesktopImporter } from './component/RatingsAndReviewsDesktopImporter';
import { StoreRatingsAndReviewsDesktop } from './component/StoreRatingsAndReviewsDesktop';
import { StoreRatingsAndReviewsMobile } from './component/StoreRatingsAndReviewsMobile';
import { StoreRatingsAndReviewsDesktopImporter } from './component/StoreRatingsAndReviewsDesktopImporter';
import { StoreRatingsAndReviewsMobileImporter } from './component/StoreRatingsAndReviewsMobileImporter';
import { RatingsAndReviewsMobile } from './component/RatingsAndReviewsMobile';
import { RatingsAndReviewsMobileImporter } from './component/RatingsAndReviewsMobileImporter';
import { RatingsAndReviewsCondensed } from './component/RatingsAndReviewsCondensed';
import { RatingsAndReviewsCondensedImporter } from './component/RatingsAndReviewsCondensedImporter';
import { RatingsAndReviewsAccordionTitle } from './component/Accordion/RatingsAndReviewsAccordionTitle';
import { RatingsAndReviewsAccordionBody } from './component/Accordion/RatingsAndReviewsAccordionBody';
import { RatingsAndReviewsAccordion } from './component/Accordion/RatingsAndReviewsAccordion';

import { dataModel } from './component/ReviewDataModel';

const WriteAReview = WriteAReviewWithPresentation;

export {
  dataModel,
  Model,
  RatingsAndReviews,
  RatingsAndReviewsDesktop,
  StoreRatingsAndReviewsDesktop,
  StoreRatingsAndReviewsMobile,
  StoreRatingsAndReviewsDesktopImporter,
  StoreRatingsAndReviewsMobileImporter,
  RatingsAndReviewsDesktopImporter,
  RatingsAndReviewsMobile,
  RatingsAndReviewsMobileImporter,
  RatingsAndReviewsCondensed,
  RatingsAndReviewsCondensedImporter,
  RatingsAndReviewsAccordion,
  RatingsAndReviewsAccordionTitle,
  RatingsAndReviewsAccordionBody,
  ReviewModel,
  ReviewSentimentModel,
  ReviewStatsModel,
  WriteAReview
};
